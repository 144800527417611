export const GUIDE_INFO_INDEX = 5;
export const EXPANDED_AUDIO_PLAYER_INDEX = 10;
export const APP_SIDEBAR_INDEX = EXPANDED_AUDIO_PLAYER_INDEX + 1;
export const COLLAPSED_AUDIO_PLAYER_INDEX = APP_SIDEBAR_INDEX + 1;
export const HAMBURGER_INDEX = 990;
export const DAILY_MOVE_INDEX = 1000;
export const SKIP_TO_MAIN_INDEX = 2147483638;
export const HEADER_INDEX = 100;
export const FULLSCREEN_LOADER_INDEX = 10000;
export const APP_INSTALL_ACTION_SHEET_INDEX = 9990;
