import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { createContext, useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import {
	useDeviceState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setPrices, updatePrice } from '@/store/prices/actions';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { getPrices } from '@/utils/endpoints/getPrices';
import { userCanTrial } from '@/utils/subscriptions';

import {
	ActiveScreenConfig,
	FlowConfig,
	FlowScreenNames,
	SimplifiedSignupContextValue,
	SimplifiedSignupProviderProps,
} from './types';

export const SimplifiedSignupContext = createContext<SimplifiedSignupContextValue>({
	activeScreenKey: 'account',
	activeScreenConfig: {} as ActiveScreenConfig,
	name: '',
	pageName: '',
	screens: {},
	hasFreeTrial: true,
	isOrganicTraffic: true,
	promotion: '',
	coupon: '',
	offerCountries: null,
	offerCurrencies: null,
	plan: 'yearly',
	setCoupon: () => {},
	logPageViewEvent: () => {},
	setFlowConfig: () => {},
});

const EXP_KEY_7_DAY_TRIAL = 'www-7-day-trial';

export const useSimplifiedSignupContext = (): SimplifiedSignupContextValue =>
	useContext(SimplifiedSignupContext);

export const SimplifiedSignupContextProvider: React.FC<SimplifiedSignupProviderProps> = ({
	children,
	flowConfig: initFlowConfig,
}) => {
	const [flowConfig, setFlowConfig] = useState<FlowConfig>(initFlowConfig);

	const {
		allowChurned = false,
		coupon,
		monthly_coupon,
		discount,
		hasFreeTrial,
		freeTrialLength,
		freeTrialLengthUnit,
		plan,
		pageName,
		name,
		promotion,
		screens,
		offerCountries,
		offerCurrencies,
		isWithinModal,
	} = flowConfig;

	const user = useUserState();
	const prices = usePricesState();

	const purchaseParams = usePurchaseParamsState();
	const device = useDeviceState();
	const canTrial = userCanTrial(user) || allowChurned;
	const dispatch = useDispatch();
	const { query, pathname } = useRouter();
	const { logEvent } = useAnalytics();
	const routerPush = useRouterPush();
	const partner = usePartnerState();

	const isOrganicTraffic = query?.utm_medium !== 'paid';
	const skip7DayTrialTest = pathname !== '/signup-flow' || !isOrganicTraffic;
	const isSecondFreeTrial = query?.utm_medium === 'second_free_trial';

	const [activeScreenKey, setActiveScreenKey] = useState<FlowScreenNames>('account');
	const activeScreenConfig: ActiveScreenConfig = useMemo(
		() => ({ ...screens[activeScreenKey], isWithinModal }),
		[activeScreenKey, screens, isWithinModal],
	);
	const ActiveScreen = useMemo(() => activeScreenConfig.component, [activeScreenConfig]);
	const showHeader = useMemo(() => screens[activeScreenKey]?.showHeader || true, [activeScreenKey, screens]);
	const { setIsModalOpen } = useSimplifiedSignupModalContext();

	// Short Circuit the experiment if the user is not coming from organic traffic
	const { isEnrolled: is7DayTrialTest, isLoading: isLoading7DayTrialTest } = useAmplitudeExperiment(
		EXP_KEY_7_DAY_TRIAL,
		skip7DayTrialTest,
	);

	const coBranded = useMemo(() => flowConfig.coBranded || undefined, [flowConfig.coBranded]);

	const isOfferCountry = useCallback((): boolean => {
		// setting both of these to null opens up the offer to all countries and currencies
		if (!offerCurrencies && !offerCountries) return true;

		return device?.ip_country && device?.ip_country !== 'unknown'
			? (offerCountries ?? []).includes(device?.ip_country || '')
			: (offerCurrencies ?? []).includes(prices.pricing_format.currency);
	}, [device, offerCountries, offerCurrencies, prices.pricing_format.currency]);

	const routeToScreen = useCallback((screen: FlowScreenNames | null): void => {
		if (screen) {
			setActiveScreenKey(screen);
		}
	}, []);

	const setCoupon = useCallback(
		async (_plan: 'yearly' | 'monthly' | 'lifetime' = 'yearly') => {
			const shouldGiveTrialMonthly = !(_plan === 'monthly' && pathname === '/signup-flow');

			function calculateDiscount() {
				if (!discount) return {};
				return discount < 1 ? { percentOff: discount * 100 } : { fixedDiscount: discount };
			}

			function calculateTrialDetails(): { units?: string; duration?: number } {
				if (!hasFreeTrial) return {};
				if (!userCanTrial(user) && !isSecondFreeTrial && !shouldGiveTrialMonthly) return {};
				if (promotion === '30_day_free_trial') return { duration: 30, units: 'day' };
				if (promotion === '7_day_free_trial') return { duration: 7, units: 'day' };
				if (!isOrganicTraffic) return { duration: 7, units: 'day' };
				if (freeTrialLength) return { duration: freeTrialLength, units: freeTrialLengthUnit || 'day' };
				return { duration: 14, units: 'day' };
			}

			const trialDetails = calculateTrialDetails();

			const shouldApplyCoupon = (coupon && canTrial && hasFreeTrial) || allowChurned;
			const couponByPlan = shouldApplyCoupon ? (_plan === 'monthly' ? monthly_coupon : coupon) : null;

			if (purchaseParams.coupon === (shouldApplyCoupon ? couponByPlan : null)) {
				if (
					trialDetails.duration &&
					trialDetails.units &&
					purchaseParams.purchaseType?.type !== 'freetrial' &&
					shouldGiveTrialMonthly
				) {
					dispatch(
						setPurchaseParams({
							...purchaseParams,
							purchaseType: {
								type: 'freetrial',
								isFreeTrial: true,
								...calculateTrialDetails(),
							},
						}),
					);
				}
				return;
			}

			const _query = {
				monthly_coupon: shouldApplyCoupon ? monthly_coupon : null,
				coupon: shouldApplyCoupon ? coupon : null,
			};

			const { data } = await getPrices(_query as ParsedUrlQuery);

			if (data) {
				const trialConditions = canTrial && hasFreeTrial && shouldGiveTrialMonthly;
				dispatch(setPrices(data));
				dispatch(
					setPurchaseParams({
						...purchaseParams,
						plan: _plan,
						...(promotion ? { promotion } : {}),
						coupon: couponByPlan,
						...calculateDiscount(),
						purchaseType: {
							...(purchaseParams?.purchaseType || {}),
							type: trialConditions ? 'freetrial' : _plan === 'monthly' ? _plan : 'subscribe',
							isFreeTrial: trialConditions,
							...calculateTrialDetails(),
						},
					}),
				);
			}
		},
		[
			canTrial,
			discount,
			dispatch,
			user,
			isSecondFreeTrial,
			purchaseParams,
			pathname,
			promotion,
			hasFreeTrial,
			coupon,
			freeTrialLength,
			freeTrialLengthUnit,
			allowChurned,
			isOrganicTraffic,
			monthly_coupon,
		],
	);

	useEffect(() => {
		setCoupon(flowConfig.plan)
			.then(() => {})
			.catch(() => {});
	}, [flowConfig.plan, setCoupon]);

	useEffect(() => {
		// Logged in and active Calm - send to wherever config dictates
		// Can be a function that triggers a side effect such as SheerID
		if (user?.subscription?.valid) {
			// if the user has a Calm premium account but is trying to claim a
			// partner offer, let the account page handle the error
			if (partner && activeScreenConfig.name === 'account') {
				noop();
			} else if (activeScreenConfig.hasPremiumSubCallback) {
				activeScreenConfig.hasPremiumSubCallback(routerPush, setIsModalOpen);
			} else if (typeof activeScreenConfig.nextScreen === 'function') {
				activeScreenConfig.nextScreen(routeToScreen, user);
			} else {
				routeToScreen(activeScreenConfig.nextScreen);
			}
		}

		// Logged in but not active Calm - send to Payment Page
		if (user && !user?.subscription?.valid) {
			if (typeof activeScreenConfig.nextScreen === 'function') {
				const _query = Object.keys(query).length ? query : undefined;
				if (!isOfferCountry()) {
					routerPush('/subscribe')
						.then(() => {})
						.catch(() => {});
				} else {
					activeScreenConfig.nextScreen(routeToScreen, user, _query, setFlowConfig);
				}
			} else {
				routeToScreen('payment');
			}
		}

		if (!user) {
			routeToScreen('account');
		}
	}, [user, activeScreenConfig, query, routeToScreen, isOfferCountry, routerPush, partner, setIsModalOpen]);

	useEffect(() => {
		if (prices.original?.yearly === prices.current?.yearly && discount) {
			const newYearly = Number(
				discount < 1 ? Math.round(prices.original.yearly - prices.original.yearly * discount) : discount,
			);
			dispatch(
				updatePrice({
					current: {
						...prices.current,
						yearly: newYearly,
					},
				}),
			);
		}
	}, [prices, discount, dispatch]);

	useEffect(() => {
		if (isLoading7DayTrialTest || !hasFreeTrial) return;
		if (is7DayTrialTest && purchaseParams.purchaseType?.duration !== 7 && !partner) {
			dispatch(
				setPurchaseParams({
					...purchaseParams,
					promotion: '7_day_free_trial',
					purchaseType: {
						...purchaseParams.purchaseType,
						type: 'freetrial',
						duration: 7,
						units: 'days',
					},
				}),
			);
		} else if (!is7DayTrialTest && isOrganicTraffic && purchaseParams.purchaseType?.duration === 7) {
			dispatch(
				setPurchaseParams({
					...purchaseParams,
					...(promotion ? { promotion } : {}),
					purchaseType: {
						...purchaseParams.purchaseType,
						type: 'freetrial',
						duration: 14,
						units: 'days',
					},
				}),
			);
		}
	}, [
		isOrganicTraffic,
		hasFreeTrial,
		dispatch,
		purchaseParams,
		user,
		is7DayTrialTest,
		isLoading7DayTrialTest,
		partner,
		promotion,
	]);

	const logPageViewEvent = useCallback(
		(screen: FlowScreenNames | null) => {
			logEvent({
				eventName: 'Page : Viewed',
				eventProps: {
					page_name: `${pageName}-${screen}`,
					source: name,
					...(name === 'spotify' && { content_source: query.spotifyShowUri ?? 'organic' }),
				},
			});
		},
		[logEvent, name, pageName, query],
	);

	const value = useMemo(
		() => ({
			activeScreenKey,
			ActiveScreen,
			activeScreenConfig,
			coBranded,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			offerCurrencies: flowConfig.offerCurrencies,
			offerCountries: flowConfig.offerCountries,
			setCoupon,
			screens: flowConfig.screens,
			hasFreeTrial: flowConfig.hasFreeTrial,
			promotion: flowConfig.promotion,
			showHeader,
			isOrganicTraffic,
			routeToScreen,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
		}),
		[
			routeToScreen,
			activeScreenKey,
			activeScreenConfig,
			ActiveScreen,
			coBranded,
			setCoupon,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			showHeader,
			isOrganicTraffic,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
		],
	);

	return <SimplifiedSignupContext.Provider value={value}>{children}</SimplifiedSignupContext.Provider>;
};
