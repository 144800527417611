import capitalize from 'lodash/capitalize';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { useCallback, useRef } from 'react';

import { useAuthContext } from '@/context/auth/AuthContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { useSetupUserAfterAuth } from '@/hooks/forms/useSetupUserAfterAuth';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useAuthFormModeState, useUserState } from '@/hooks/store';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { User } from '@/store/user/types';
import { getAuth0Endpoint } from '@/utils/auth0';
import getUrl from '@/utils/getUrl';
import { CalmAuthMethods } from '@/utils/privacyCookies';

export enum BroadcastChannelName {
	TAB_COMMUNICATION = 'TAB_COMMUNICATION',
}

export enum BroadcastChannelEvent {
	LOGIN_COMPLETED = 'LOGIN_COMPLETED',
	SIGNUP_COMPLETED = 'SIGNUP_COMPLETED',
	WINDOW_CLOSED = 'WINDOW_CLOSED',
}

type SignupArgs = {
	authMethod: CalmAuthMethods;
	marketingOptIn: boolean;
	onAuthSuccess?: (user: User) => void;
};

interface UseAuth {
	authenticate: (args: SignupArgs) => void;
	isAuth0Enabled: boolean | undefined;
	isLoading: boolean;
	isAuth0User: boolean;
}

const hardcodedAuthEnabledPartners = ['test-calm-group-code', 'ews'];

export function useAuth(): UseAuth {
	const { query } = useRouter();
	const user = useUserState();

	const shouldAlwaysShowAuth =
		typeof query?.partnerSlug === 'string' ? hardcodedAuthEnabledPartners.includes(query.partnerSlug) : false;

	// don't need to call the experiment if it's always enabled
	const { isEnrolled, isLoading } = useAmplitudeExperiment('auth0-login', shouldAlwaysShowAuth);
	const apiRequest = useApi();

	const setupUserAfterAuth = useSetupUserAfterAuth();
	const { setIsAuthenticating } = useAuthContext();
	const authFormMode = useAuthFormModeState();
	const signupPopup = useRef<Window | null>(null);
	const { logEvent } = useAnalytics();
	const guestPassCode = useGuestPassRequestCode();

	const authenticate = useCallback(
		({ authMethod, marketingOptIn, onAuthSuccess }: SignupArgs) => {
			logEvent({
				eventName: `Login Form : ${capitalize(authMethod)} : Clicked`,
				eventProps: {
					mode: authFormMode,
				},
			});
			setIsAuthenticating(true);

			const channel = new BroadcastChannel(BroadcastChannelName.TAB_COMMUNICATION);
			channel.onmessage = async function (event) {
				const { type } = event.data;

				if (
					type === BroadcastChannelEvent.LOGIN_COMPLETED ||
					type === BroadcastChannelEvent.SIGNUP_COMPLETED
				) {
					// Close channel, so that we avoid catching any subsequent events (WINDOW_CLOSED in particular).
					channel.close();

					return apiRequest<User>({
						endpoint: 'v2/me',
						method: 'GET',
					})
						.then(async ({ data }) => {
							await setupUserAfterAuth(data, authMethod);
							return onAuthSuccess?.(data);
						})
						.catch(() => {
							logEvent({
								eventName: `Login Form : ${capitalize(authMethod)} : Error`,
								eventProps: {
									mode: type === BroadcastChannelEvent.LOGIN_COMPLETED ? 'login' : 'signup',
								},
							});
						})
						.finally(() => {
							logEvent({
								eventName: `Login Form : ${capitalize(authMethod)} : Success`,
								eventProps: {
									mode: type === BroadcastChannelEvent.LOGIN_COMPLETED ? 'login' : 'signup',
								},
							});
							setIsAuthenticating(false);
						});
				}

				if (type === BroadcastChannelEvent.WINDOW_CLOSED) {
					logEvent({
						eventName: `Login Form : ${capitalize(authMethod)} : Cancelled`,
						eventProps: {
							mode: authFormMode,
						},
					});
					channel.close();
					setIsAuthenticating(false);
				}
			};

			if (signupPopup.current) {
				signupPopup.current.focus();
			} else {
				const left = (window.innerWidth - 600) / 2 + window.screenX;
				const top = (window.innerHeight - 600) / 2 + window.screenY;
				signupPopup.current = window.open(
					`${getUrl('web-api')}/auth/${getAuth0Endpoint(authMethod, authFormMode ?? 'signup')}?${qs.stringify(
						{
							marketing_opt_in: marketingOptIn,
							guest_pass_code: guestPassCode,
						},
					)}`,
					'_blank',
					`popup,width=600,height=600,top=${top},left=${left}`,
				);

				if (signupPopup.current) {
					const interval = setInterval(() => {
						if (signupPopup.current?.closed) {
							signupPopup.current = null;
							clearInterval(interval);
							const channel = new BroadcastChannel(BroadcastChannelName.TAB_COMMUNICATION);
							channel.postMessage({ type: BroadcastChannelEvent.WINDOW_CLOSED });
						}
					}, 500);
				}
			}
		},
		[apiRequest, authFormMode, guestPassCode, logEvent, setIsAuthenticating, setupUserAfterAuth],
	);

	// should fallback to isEnrolled if shouldAlwaysShowAuth is false
	const isAuth0Enabled = shouldAlwaysShowAuth || isEnrolled;

	return { isAuth0Enabled, authenticate, isLoading, isAuth0User: user?.current_auth_type === 'auth0' };
}
